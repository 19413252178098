import React from 'react'
import PropTypes from 'prop-types'
import style from './Pill.module.css'

const Pill = ({label, isSelected, onTap}) => {
  return (
    <div className={isSelected ? style.active : style.pill} onClick={onTap}>
      {label}
    </div>
  )
}

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onTap: PropTypes.func
}

export default Pill
