import React, { useCallback } from 'react';
import { motion } from 'framer-motion';
// import ExpandedCard from './ExpandedCard'
// import Container from '../../hoc/container/Container';
// import { BoatData } from '../../model/boat';
import { formatNumber } from '../../functions/functions';
import { navigate } from 'gatsby';

import style from './BoatCard.module.css';

// type BoatCardProps = {
// 	boat: BoatData;
// };

const BoatCard = ({ boat }) => {
	const price = boat.price || { weekly: 0, currency: '' };
	const sleeps = (boat.guests && boat.guests.sleeps) || '';
	const formattedPrice = formatNumber(price.weekly);

	const onTap = useCallback(() => navigate(`/${boat.slug.current}`), []);
	return (
		<div className={style.tileContainer} onClick={onTap}>
			{/* TODO: Insert Gatsby Image */}
			<div className={style.tileContent}>
				<motion.img
					className={style.image}
					src={boat.headerImage && boat.headerImage.asset.url}
					alt={boat.title + ' Photo'}
					whileHover={{ scale: 1.06 }}
					transition={{ duration: 1 }}
				/>
				{/* Title And Boat Type */}
				<div className={style.tileTitle}>
					<span>{boat.type}</span>
					<h3>{boat.title}</h3>
				</div>

				<div className={style.tileDetails}>
					<span>
						{price.currency}
						{formattedPrice} p/week
					</span>
					<div />
					<span>Sleeps {sleeps}</span>
				</div>
			</div>
		</div>
	);
};

export default BoatCard;

// <AnimatePresence>
// {expandCard && (
//   <motion.div
//     key="modalDetails"
//     className={style.modalDetails}
//     initial={false}
//     animate={{opacity: 1}}
//     exit={{opacity: 0}}
//   >
//     <Container>
//       <span>
//         {price.currency}
//         {formattedPrice(price.weekly)} p/week
//       </span>

//       <p>Some Expanded Text</p>
//       <p>Some Expanded Text</p>
//       <p>Some Expanded Text</p>

//       <span>Sleeps {sleeps}</span>
//     </Container>
//   </motion.div>
// )}
// </AnimatePresence>
