import React, { useState, useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types'
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';
// import {Transition} from 'react-transition-group'
import { lockScroll, unlockScroll } from '../../../functions/functions';

// import gsap from 'gsap'
import Pill from '../../ui/pill/Pill';
import Button from '../../ui/button/Button';
import Slider from '../../ui/slider/Slider';
import style from './FilterModal.module.css';

const FilterModal = ({ show, contentKey, closeModal, options, setOptions, allLocations }) => {
	const [updatedOptions, setUpdatedOptions] = useState({ ...options });

	const bgVariants = {
		visible: {
			opacity: 0.5,
		},
		exit: {
			opacity: 0,
		},
	};
	const modalVariants = {
		initial: {
			opacity: 0,
			y: '50%',
		},
		visible: {
			opacity: 1,
			y: '0%',
			transition: {
				when: 'beforeChildren',
				staggerChildren: 0.1,
			},
		},
		exit: {
			opacity: 0,
			y: '25%',
			transition: {
				when: 'afterChildren',
			},
		},
	};

	const contentVariants = {
		initial: { opacity: 0, y: 16 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0 },
	};

	useEffect(() => {
		if (show) lockScroll();
		if (!show) unlockScroll();
	}, [show]);

	// Locations
	const addOrRemoveLocationFromOptions = useCallback(
		(locationId) => {
			// Prepare the locations array
			let updatedLocations = [...updatedOptions.locations];
			const indexOfId = updatedLocations.indexOf(locationId);
			if (indexOfId === -1) {
				// Add location id
				updatedLocations.push(locationId);
			} else {
				// Remove location id
				updatedLocations.splice(indexOfId, 1);
			}
			// Update the options object
			let update = _.cloneDeep(updatedOptions);
			update.locations = updatedLocations;
			setUpdatedOptions(update);
		},
		[updatedOptions, setUpdatedOptions]
	);

	const clearLocationsFilter = useCallback(() => {
		// Update the options object
		let update = _.cloneDeep(updatedOptions);
		update.locations = [];
		setUpdatedOptions(update);
	}, [updatedOptions, setUpdatedOptions]);

	// Price Range
	const updatePriceInOptions = useCallback(
		(vals) => {
			let update = _.cloneDeep(updatedOptions);
			update.price = { ...vals };
			setUpdatedOptions(update);
		},
		[updatedOptions, setUpdatedOptions]
	);

	// Guests (Sleeps)
	const updateGuests = useCallback(
		(vals) => {
			let update = _.cloneDeep(updatedOptions);
			update.guests = { sleeps: vals.max };
			setUpdatedOptions(update);
		},
		[updatedOptions, setUpdatedOptions]
	);

	// Save Options
	const saveUpdatedOptions = useCallback(() => {
		closeModal();
		setOptions(updatedOptions);
	}, [updatedOptions]);

	// Render
	const returnFilterModalContent = (contentKey) => {
		if (contentKey === 'locations') {
			return (
				<>
					<motion.h3 variants={contentVariants}>Where are you interested in going?</motion.h3>
					<motion.div variants={contentVariants} className={style.locations}>
						{allLocations.map((location) => {
							const isSelected = updatedOptions.locations.includes(location.id);
							return (
								<Pill
									key={location.name}
									label={location.name}
									isSelected={isSelected}
									onTap={() => addOrRemoveLocationFromOptions(location.id)}
								/>
							);
						})}
						<button onClick={clearLocationsFilter} className="yellowColour" style={{ width: 240, marginTop: 16 }}>
							Clear filter
						</button>
					</motion.div>
				</>
			);
		} else if (contentKey === 'price') {
			return (
				<>
					<motion.h3 variants={contentVariants}>What's your weekly price range?</motion.h3>
					<motion.div variants={contentVariants}>
						<Slider
							name="price"
							values={options.price}
							unit="€"
							min={0}
							max={1000000}
							increment={10000}
							isRange={true}
							changed={updatePriceInOptions}
						/>
					</motion.div>
				</>
			);
		} else if (contentKey === 'guests') {
			return (
				<>
					<motion.h3 variants={contentVariants}>How many guests need to sleep?</motion.h3>
					<motion.div variants={contentVariants}>
						<Slider
							name="guests"
							values={{ min: 0, max: options.guests.sleeps }}
							min={0}
							max={12}
							increment={1}
							changed={updateGuests}
							isRange={false}
						/>
					</motion.div>
				</>
			);
		} else {
			return <h3>{contentKey} Selected</h3>;
		}
	};

	return (
		<div className={style.modalContainer}>
			<AnimatePresence>
				{show && (
					<motion.div
						key="modalBg"
						initial="exit"
						animate="visible"
						exit="exit"
						variants={bgVariants}
						className={style.modalBg}
						onClick={closeModal}
					/>
				)}
				{show && (
					<motion.div
						key="modal"
						initial="initial"
						animate="visible"
						exit="exit"
						variants={modalVariants}
						className={style.modal}
					>
						{returnFilterModalContent(contentKey)}

						<motion.div variants={contentVariants}>
							<Button label="Apply" isTertiary isOutlined={true} isFullWidth={true} onPress={saveUpdatedOptions} />
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default FilterModal;

// price: {
//   title: 'PRICE'
// },
// locations: {
//   title: 'LOCATIONS',
//   content: (
//
//     </div>
//   )
// },
// guests: {
//   title: 'GUESTS'
// },
// type: {
//   title: 'VESSEL TYPE'
// }
