// Functions for Filtering and Sorting
// import { BoatData, BoatFilterOptions } from '../model/boat';

export const filterBoats = (allBoats, options) => {
	let filteredBoats = allBoats;
	console.log('filterBoats start:', allBoats, options);

	// Filter by Locations

	// boat.locations: Array(5)
	// 0: {name: "Port Douglas", id: "2c5dda13-e4a1-53e8-ae18-f26a40721c12"}
	// 1: {name: "The Whitsundays", id: "fb685740-9433-5465-8801-80a10842b68c"}
	// 2: {name: "Great Barrier Reef", id: "4eb2d03d-7f34-55f2-a4c7-f10bade59b5b"}
	const filterLocation = (locations) => locations.find((location) => options.locations.includes(location.id));
	// Return true or false
	if (options.locations.length === 0) {
		console.log('Not filtering locations');
	} else {
		filteredBoats = filteredBoats.filter((boat) => filterLocation(boat.locations));
	}
	// Filter by Price
	// options : { price: { min: 0, max: 100}}
	const filterWeeklyPrice = (weeklyPrice) => {
		if (weeklyPrice < options.price.min) return false;
		if (weeklyPrice > options.price.max) return false;
		return true;
	};
	filteredBoats = filteredBoats.filter((boat) => boat.price && filterWeeklyPrice(boat.price.weekly));

	const filterGuestsSleeps = (sleeps) => sleeps >= options.guests.sleeps;

	filteredBoats = filteredBoats.filter((boat) => boat.guests && boat.guests.sleeps && filterGuestsSleeps(boat.guests.sleeps));

	return filteredBoats;
};
