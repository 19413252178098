import React, { useState, useEffect, useRef, useCallback, useLayoutEffect, useMemo } from "react";
import PropTypes from "prop-types";
import style from "./Slider.module.css";
import { formatNumber } from "../../../functions/functions";

const Slider = ({ values, min, max, increment, unit, label, changed, disabled, isRange }) => {
  // TODO: Update to work for negative values and min values other than 0.
  // MF: Currently this component is only set up to work between 0 & X where X is positive.

  const [valueMin, setValueMin] = useState(Math.max(values.min, min));
  const [valueMax, setValueMax] = useState(Math.min(values.max, max));

  const [isDragging, setIsDragging] = useState(false);
  const track = useRef(null);
  const trackWidth = useRef(null);
  // How many pixels move 1 increment
  const pixelsPerIncrement = useMemo(() => (trackWidth.current / max) * increment, [
    trackWidth.current,
    max,
    increment,
  ]);
  const dragHandleSize = 40;
  let startX = useRef(0);
  let sliderChanged = useRef(null);

  // Set the track width on layout
  useLayoutEffect(() => {
    trackWidth.current = track.current.offsetWidth;
  }, [track.current]);

  useEffect(() => {
    // When the dragging stops, call the changed function, passing the updated value.
    if (!isDragging) {
      changed({ min: valueMin, max: valueMax });
    }
  }, [isDragging]);

  const startDrag = (e) => {
    sliderChanged.current = e.currentTarget.getAttribute("name");
    if (disabled) return;
    e.preventDefault();
    startX.current = e.clientX;
    window.addEventListener("pointermove", moveDrag);
    window.addEventListener("pointerup", cancelDrag);
    setIsDragging(true);
  };

  const moveDrag = useCallback(
    (e) => {
      const moveX = e.clientX - startX.current;
      updateValue(moveX);
    },
    [startDrag]
  );

  const roundValue = useCallback(
    (value) => {
      // Remainder from increment
      const remainder = value % increment;
      // Is the remainder less than half an increment? Round down. Else round up.
      return remainder < increment / 2 ? value - remainder : value + increment - remainder;
    },
    [increment]
  );

  const updateValue = (moveX) => {
    // console.log('moveX', moveX)
    const valueChange = (moveX / pixelsPerIncrement) * increment;
    // Min value
    if (sliderChanged.current === "minSlider") {
      // const newValue = valueMin + valueChange
      const newValue = roundValue(valueMin + valueChange);
      // console.log('new value:', newValue)
      if (newValue < min) return setValueMin(min);
      if (newValue < valueMax) return setValueMin(newValue);
      // Max value
    } else {
      const newValue = roundValue(valueMax + valueChange);
      // console.log('new value:', newValue)
      if (newValue > max) return setValueMax(max);
      if (newValue <= max && newValue > valueMin) return setValueMax(newValue);
    }
  };

  const cancelDrag = useCallback(() => {
    setIsDragging(false);
    window.removeEventListener("pointermove", moveDrag);
    window.removeEventListener("pointerup", cancelDrag);
  }, [startDrag]);

  // TODO: Clicking on Track
  // Touch on the track/highlight to set the value directly
  // Worked out as a percentage and rounded to nearest whole number.
  const formattedMinValue = formatNumber(valueMin);
  const formattedMaxValue = formatNumber(valueMax);

  return (
    <div className={style.container}>
      {/* {label && (
        <label className={style.label} htmlFor={name}>
          {label}:
        </label>
      )} */}
      <div className={style.values}>
        <h4>
          {isRange && (
            <>
              {unit}
              {formattedMinValue}
            </>
          )}
        </h4>
        <h4>
          {unit}
          {formattedMaxValue}
        </h4>
      </div>
      <div className={style.slider}>
        <div ref={track} className={style.track}>
          <div
            className={style.highlightMin}
            style={{
              width: `calc(${((valueMax - valueMin) / max) * 100}%)`,
              left: `${(valueMin / max) * 100}%`,
            }}
          />
          {/* Min Value */}
          {isRange ? (
            <div
              className={style.dragHandle}
              style={{
                width: dragHandleSize,
                height: dragHandleSize,
                left: `calc(${(valueMin / max) * 100}% - ${dragHandleSize / 2}px)`,
                cursor: disabled ? "initial" : isDragging ? "grabbing" : "grab",
              }}
              role="input"
              name="minSlider"
              onPointerDown={startDrag}
            />
          ) : null}
          {/* Max Value */}
          <div
            className={style.dragHandle}
            style={{
              width: dragHandleSize,
              height: dragHandleSize,
              left: `calc(${(valueMax / max) * 100}% - ${dragHandleSize / 2}px)`,
              cursor: disabled ? "initial" : isDragging ? "grabbing" : "grab",
            }}
            role="input"
            name="maxSlider"
            onPointerDown={startDrag}
          />
        </div>
      </div>
      {/* {markers && (
        <div className={style.markersContainer}>
          {markers.map((marker, index) => {
            return (
              <p key={index} style={{opacity: 1}}>
                {marker}
              </p>
            )
          })}
          {markers[0]}
          {markers[markers]}
        </div>
      )} */}
    </div>
  );
};

Slider.propTypes = {
  increment: PropTypes.number.isRequired,
  changed: PropTypes.func.isRequired,
  label: PropTypes.string,
  // markers: PropTypes.array,
  values: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  // highlightColour: PropTypes.string,
  trackColour: PropTypes.string,
  disabled: PropTypes.bool,
  isRange: PropTypes.bool.isRequired,
  unit: PropTypes.string,
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  values: { min: 0, max: 100 },
};

export default Slider;
