import React, { useState, useCallback, useContext, useMemo } from 'react';
// import {Transition} from 'react-transition-group'
// import {lockScroll, unlockScroll} from '../../functions/functions'
import style from './FilterBar.module.css';
import Container from '../../hoc/container/Container';
import Pill from '../ui/pill/Pill';
import Modal from './filterModal/FilterModal';

import WindowContext from '../../contexts/WindowContext';

import { formatNumber } from '../../functions/functions';
import { defaultFilterOptions } from '../../pages/browse';

import priceIcon from '../../assets/icons/filter/price.svg';
import locationIcon from '../../assets/icons/filter/location.svg';
import sleepsIcon from '../../assets/icons/filter/sleeps.svg';

const FilterBar = ({ options, setOptions, allLocations }) => {
	const [showModal, setShowModal] = useState(false);
	// The content to be shown in the modal.
	const [modalContentKey, setModalContentKey] = useState('price'); // e.g "price"

	const windowDimensions = useContext(WindowContext);
	const isMobile = useMemo(() => windowDimensions.width < 768, [windowDimensions]);

	const handleButtonPressed = useCallback((contentKey) => {
		setModalContentKey(contentKey);
		setShowModal(true);
	}, []);

	const closeModalPressed = useCallback(() => {
		setShowModal(false);
	}, []);

	const priceIsDefault =
		options.price.min === defaultFilterOptions.price.min && options.price.max === defaultFilterOptions.price.max;
	const minPriceFormatted = formatNumber(options.price.min);
	const maxPriceFormatted = formatNumber(options.price.max);

	return (
		<>
			<div className={style.container}>
				<Container large containerClass={style.grid}>
					<div className={style.option} onClick={() => handleButtonPressed('locations')}>
						<div>
							<img src={locationIcon} alt="locations" />
							<h4>LOCATIONS</h4>
						</div>
						<div>
							{isMobile ? (
								<span className="yellowColour">{options.locations.length || 'All'} Selected</span>
							) : options.locations.length ? (
								options.locations.map((locationId) => {
									// TODO: Optimise this!!!
									const location = allLocations.filter((location) => location.id === locationId)[0];
									return <Pill key={locationId} label={location.name} isSelected />;
								})
							) : (
								<span className="yellowColour">All selected</span>
							)}
						</div>
					</div>
					{/* Price Section */}
					<div className={style.option} onClick={() => handleButtonPressed('price')}>
						<div>
							<img src={priceIcon} alt="price" />
							<h4>PRICE</h4>
						</div>
						<div>
							<span className="yellowColour">
								{/* On moobile the actual values aren't displayed in the bar. there is an indicator as to if the filter is set or not */}
								{isMobile
									? `${priceIsDefault ? 'No limit' : 'Filtered'}`
									: `€${minPriceFormatted} - €${maxPriceFormatted} p/week`}
							</span>
						</div>
					</div>
					{/* Guests */}
					<div className={style.option} onClick={() => handleButtonPressed('guests')}>
						<div>
							<img src={sleepsIcon} alt="guests" />
							<h4>SLEEPS</h4>
						</div>
						<div>
							<span className="yellowColour">
								{options.guests.sleeps > 0 ? `${options.guests.sleeps}+` : 'Any'}
							</span>
						</div>
					</div>
				</Container>
			</div>
			<Modal
				show={showModal}
				contentKey={modalContentKey}
				options={options}
				setOptions={setOptions}
				allLocations={allLocations}
				closeModal={closeModalPressed}
			/>
		</>
	);
};

FilterBar.propTypes = {};
FilterBar.defaultProps = {};

export default FilterBar;
