import React, { useEffect, useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	//   filterOutDocsPublishedInTheFuture
} from '../lib/helpers';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import BoatCard from '../components/boatCard/BoatCard';
import FilterBar from '../components/filterBar/FilterBar';
import NavButton from '../components/navigation/navButton/NavButton';
import Button from '../components/ui/button/Button';

import { filterBoats } from '../functions/filter';
// import { BoatData, BoatFilterOptions } from '../model/boat';
import style from './browse.module.css';

// TODO: Need to test if the filter will work for nested values such as the weekly price inside the prices object.
export const query = graphql`
	query BrowsePageQuery {
		locations: allSanityLocation(sort: { fields: name }) {
			edges {
				node {
					id
					name
					country {
						name
						id
					}
				}
			}
		}
		boats: allSanityBoat(limit: 100) {
			edges {
				node {
					id
					title
					type
					slug {
						current
					}
					guests {
						sleeps
						cruises
					}
					price {
						currency
						weekly
					}
					locations {
						name
						id
					}
					headerImage {
						asset {
							url
						}
					}
				}
			}
		}
	}
`;

export const defaultFilterOptions = {
	price: { min: 0, max: 1000000 },
	locations: [],
	guests: {
		sleeps: 0,
	},
};

const BrowsePage = (props) => {
	const { data, errors } = props;

	const boatNodes = useMemo(() => ((data || {}).boats ? mapEdgesToNodes(data.boats).filter(filterOutDocsWithoutSlugs) : []), [
		data,
	]);

	const [boats, setBoats] = useState(boatNodes);
	const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);

	useEffect(() => {
		const results = filterBoats(boatNodes, filterOptions);
		// console.log('BrowsePage filterOptions:', filterOptions);
		// console.log('BrowsePage results:', results);
		setBoats(results);
	}, [filterOptions, boatNodes]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [boats]);

	if (errors) {
		return <GraphQLErrorList errors={errors} />;
	}

	const locationNodes = (data || {}).locations ? mapEdgesToNodes(data.locations) : [];

	return (
		<>
			<SEO title="Charter Motor and Sail Yachts with Superior Yachting" />
			<FilterBar allLocations={locationNodes} options={filterOptions} setOptions={setFilterOptions} />
			<section className={style.boats}>
				{boats.map((boat, index) => (
					<BoatCard key={index} boat={boat} />
				))}
				<div className={style.moreBoatsCard}>
					<h4>Can't find what you're looking for?</h4>
					<p>
						Many Yachts are currently offline.
						<br />
						Please contact us for all your options.
					</p>
					<a href="mailto:info@superioryachting.com" alt="Email Superior Yachting">
						<Button label="Get In Touch" isTertiary={true} isOutlined={true} onPress={() => {}} />
					</a>
				</div>
			</section>
		</>
	);
};

BrowsePage.propTypes = {};
BrowsePage.defaultProps = {};

export default BrowsePage;

// TODO: Create Layout Transition
// <AniLink to={boat.slug.current} fade>
